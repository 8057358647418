@tailwind base;
@tailwind components;
@tailwind utilities;

:root,
.light,
.light-theme {
  --violet-1: #fdfcfe;
  --violet-2: #faf8fd;
  --violet-3: #f4effd;
  --violet-4: #ede4fb;
  --violet-5: #e4d8f7;
  --violet-6: #d8c9f1;
  --violet-7: #c9b4e8;
  --violet-8: #b599dc;
  --violet-9: #8056b2;
  --violet-10: #7249a1;
  --violet-11: #784fa8;
  --violet-12: #3c2657;

  --violet-a1: #5500aa03;
  --violet-a2: #4900b707;
  --violet-a3: #5000e010;
  --violet-a4: #5500da1b;
  --violet-a5: #4f00cb27;
  --violet-a6: #4700bd36;
  --violet-a7: #4801b14b;
  --violet-a8: #4600a866;
  --violet-a9: #40008ba9;
  --violet-a10: #3a007bb6;
  --violet-a11: #3c0081b0;
  --violet-a12: #1a003ad9;

  --violet-contrast: #fff;
  --violet-surface: #f9f6fdcc;
  --violet-indicator: #8056b2;
  --violet-track: #8056b2;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root,
    .light,
    .light-theme {
      --violet-1: oklch(99.3% 0.003 303);
      --violet-2: oklch(98.2% 0.0074 303);
      --violet-3: oklch(96% 0.019 303);
      --violet-4: oklch(93.3% 0.0316 303);
      --violet-5: oklch(90.1% 0.0443 303);
      --violet-6: oklch(86% 0.0577 303);
      --violet-7: oklch(80.5% 0.0752 303);
      --violet-8: oklch(73.2% 0.0994 303);
      --violet-9: oklch(54% 0.1432 303);
      --violet-10: oklch(49.3% 0.1394 303);
      --violet-11: oklch(51.5% 0.1394 303);
      --violet-12: oklch(32% 0.0877 303);

      --violet-a1: color(display-p3 0.349 0.0235 0.6745 / 0.012);
      --violet-a2: color(display-p3 0.302 0.0235 0.7216 / 0.028);
      --violet-a3: color(display-p3 0.2549 0.0078 0.8157 / 0.063);
      --violet-a4: color(display-p3 0.2627 0.0039 0.7804 / 0.106);
      --violet-a5: color(display-p3 0.2588 0.0039 0.7451 / 0.153);
      --violet-a6: color(display-p3 0.2118 0.0039 0.6824 / 0.208);
      --violet-a7: color(display-p3 0.2196 0.0039 0.6392 / 0.291);
      --violet-a8: color(display-p3 0.2196 0.0039 0.6039 / 0.397);
      --violet-a9: color(display-p3 0.2039 0 0.5098 / 0.655);
      --violet-a10: color(display-p3 0.1843 0 0.451 / 0.706);
      --violet-a11: color(display-p3 0.1922 0 0.4706 / 0.683);
      --violet-a12: color(display-p3 0.0824 0 0.2078 / 0.848);

      --violet-contrast: #fff;
      --violet-surface: color(display-p3 0.9765 0.9647 0.9922 / 0.8);
      --violet-indicator: oklch(54% 0.1432 303);
      --violet-track: oklch(54% 0.1432 303);
    }
  }
}

body {
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}

.bg-stripe-gradient {
  background: repeating-linear-gradient(
    -55deg,
    #1f8269,
    #1f8269 8px,
    #34a387 8px,
    #34a387 13px
  );
}

.non-draggale {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.spin-wheel-animation {
  transform: rotate(var(--start-rotation-degrees));
}

.spin-wheel-animation.started-spinning {
  animation: spin-wheel var(--start-spinning-time)
      cubic-bezier(0.71, var(--spin-wheel-cb-arg), 0.96, 0.9) 0s 1 normal
      forwards running,
    continueSpin-wheel var(--continue-spinning-time) linear
      var(--start-spinning-time) 1 normal forwards running,
    stopSpin-wheel var(--stop-spinning-time) cubic-bezier(0, 0, 0.35, 1.02)
      var(--stop-spin-time-duration) 1 normal forwards running;
}

@keyframes spin-wheel {
  from {
    transform: rotate(var(--start-rotation-degrees));
  }
  to {
    transform: rotate(var(--start-rotation-degree-complete));
  }
}
@keyframes continueSpin-wheel {
  from {
    transform: rotate(var(--start-rotation-degrees));
  }
  to {
    transform: rotate(var(--start-rotation-degree-complete));
  }
}
@keyframes stopSpin-wheel {
  from {
    transform: rotate(var(--start-rotation-degrees));
  }
  to {
    transform: rotate(var(--final-rotation-degrees));
  }
}
